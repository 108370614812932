@charset "UTF-8";
:root {
	--bs-primary: #004aad;
	--bs-secondary: #6190b9;
	--tb-alternate: #fff;
	--tb-black: #000;
}

/* * {
	border: 1px dashed red;
} */

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 500;
	line-height: 1.2;
}

a {
	color: var(--tb-black);
	text-decoration: none;
}
a:hover {
	color: var(--bs-primary);
}

.btn-primary {
	background-color: var(--bs-primary);
}
.btn-primary:hover {
	background-color: var(--tb-alternate);
	color: var(--bs-primary);
}
.btn-primary:active {
	background-color: var(--bs-secondary);
	color: var(--tb-alternate);
}

.page-section {
	padding: 4rem 0;
}
.page-section h2.section-heading,
.page-section .section-heading.h2 {
	font-size: 2.5rem;
	margin-top: 0;
	margin-bottom: 1rem;
}
.page-section h3.section-subheading,
.page-section .section-subheading.h3 {
	font-size: 1rem;
	font-weight: 400;
	font-style: italic;
	font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	section {
		padding: 9rem 0;
	}
}
.btn-xl {
	padding: 1.25rem 2.5rem;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1.125rem;
	font-weight: 700;
}

.btn-social {
	height: 2.5rem;
	width: 2.5rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border-radius: 100%;
}

header.masthead {
	padding-top: 10.5rem;
	padding-bottom: 6rem;
	text-align: center;
	color: #fff;
	background-image: url("../src/assets/images/header-bg.webp");
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center center;
	background-size: cover;
}
header.masthead .masthead-subheading {
	font-size: 1.5rem;
	font-style: italic;
	line-height: 1.5rem;
	margin-bottom: 25px;
	font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header.masthead .masthead-heading {
	font-size: 3.25rem;
	font-weight: 700;
	line-height: 3.25rem;
	margin-bottom: 1rem;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 768px) {
	header.masthead {
		padding-top: 17rem;
		padding-bottom: 12.5rem;
	}
	header.masthead .masthead-subheading {
		font-size: 2.25rem;
		font-style: italic;
		line-height: 2.25rem;
		margin-bottom: 2rem;
	}
	header.masthead .masthead-heading {
		font-size: 4.5rem;
		font-weight: 700;
		line-height: 4.5rem;
		margin-bottom: 2rem;
	}
}

/* SERVICE STYLING */
#service .section-heading {
	text-align: center;
	font-size: 3rem;
	font-weight: 600;
	padding-bottom: 2rem;
	border-bottom: var(--bs-primary) 5px solid;
}
#service .service-caption {
	padding: 1.5rem;
	text-align: center;
	font-size: 1.25rem;
}
#service .service-caption a {
	color: var(--bs-primary);
}
#service .service-caption a:hover {
	color: var(--bs-secondary);
}

/* CONTACT SECTION STYLING */
section#contact {
	background-color: #212529;
	background-image: url("../src/assets/images/map-image.webp");
	background-repeat: no-repeat;
	background-position: center;
}
section#contact .section-heading {
	color: #fff;
}
section#contact form#contactForm .form-group {
	margin-bottom: 1.5rem;
}
section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
	padding: 1.25rem;
}
section#contact form#contactForm .form-group input.form-control {
	height: auto;
}
section#contact form#contactForm .form-group-textarea {
	height: 100%;
}
section#contact form#contactForm .form-group-textarea textarea {
	height: 100%;
	min-height: 10rem;
}
section#contact form#contactForm p.help-block {
	margin: 0;
}
section#contact form#contactForm .form-control:focus {
	border-color: var(--bs-secondary);
	box-shadow: none;
}
section#contact form#contactForm ::-webkit-input-placeholder {
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	color: #ced4da;
}
section#contact form#contactForm :-moz-placeholder {
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	color: #ced4da;
}
section#contact form#contactForm ::-moz-placeholder {
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	color: #ced4da;
}
section#contact form#contactForm :-ms-input-placeholder {
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	color: #ced4da;
}

.footer {
	text-align: center;
	font-size: 0.9rem;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
